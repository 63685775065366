<template>
  <div class="page_content">
    <div class="banner_content">
      <el-carousel class="banner_carousel" indicator-position="outside">
        <el-carousel-item v-for="(item, index) in bannerImag" :key="index">
          <img class="banner_img" :src="item.value" alt="" @click="toPage(item.link, index)">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui'
import { logClickBanner } from '@/utils/log';

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  props: {
    bannerImag: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toPage(url, index) {
      url && window.open(url, '_blank')
      logClickBanner(index + 1)
    }
  }
}
</script>

<style lang="scss">
@import '@/style/var';

.device-pc {
  .pay_layout_banner {
    min-width: $pc-content-width;
    background-repeat: no-repeat;
    background-position: center center;

    .banner_content {
      .banner_carousel {
        .banner_img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        .el-carousel__container {
          border-radius: 20px;
          overflow: hidden;
          height: 250px;
        }

        .el-carousel__indicators{
          .el-carousel__indicator--horizontal{
            .el-carousel__button {
              background: rgba(252, 89, 46, 0.5);
              width: 10px;
              height: 10px;
              border-radius: 5px;
            }
          }
          .is-active{
            .el-carousel__button {
              background: #fc892e;
              width: 10px;
              height: 10px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
</style>