var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticStyle: { background: "transparent" },
      attrs: {
        "get-container": "body",
        round: "",
        position: _vm.isMobile ? "bottom" : "center",
      },
      on: {
        close: function ($event) {
          return _vm.onClose()
        },
      },
      model: {
        value: _vm.visibleRole,
        callback: function ($$v) {
          _vm.visibleRole = $$v
        },
        expression: "visibleRole",
      },
    },
    [
      _vm.loading
        ? _c("van-loading", {
            staticClass: "roles-loading",
            attrs: { color: "#FE7B10", size: "60px" },
          })
        : _c(
            "div",
            {
              staticClass: "pop-role-container",
              class: _vm.isMobile ? "mobile" : "pc",
            },
            [
              _c(
                "div",
                { staticClass: "pop-role-topbar" },
                [
                  _vm._v(" " + _vm._s(_vm.$sys("account.changeRole")) + " "),
                  _c("van-icon", {
                    attrs: { color: "#BDBDBD", name: "cross" },
                    on: {
                      click: function ($event) {
                        return _vm.onClose()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pop-role-content" },
                [
                  _vm.roles.length > 0
                    ? _c(
                        "ul",
                        { ref: "listRef", staticClass: "pop-role-list" },
                        [
                          _vm._l(_vm.roles, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: item.gameRoleCode,
                                class: [
                                  "pop-role-list__item",
                                  _vm.selectedIndex === index ? "selected" : "",
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSelected(item, index)
                                  },
                                },
                              },
                              [
                                item.gameRoleCode === _vm.latestLoginRoleCode
                                  ? _c(
                                      "span",
                                      { staticClass: "pop-role-list__mark" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$sys("account.latestLogin")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "15px" } },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(item.gameRoleName) +
                                          " (LV." +
                                          _vm._s(item.gameRoleLevel || "") +
                                          ")"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "#B9B9B9",
                                      padding: "3px 0",
                                      "padding-bottom": "0",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$sys("account.server")) +
                                          "：" +
                                          _vm._s(item.gameZoneName)
                                      ),
                                    ]),
                                    _vm._v("   "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$sys("account.roleID")) +
                                          "：" +
                                          _vm._s(item.gameRoleCode)
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "block",
                                          "margin-top": "3px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$sys("account.latestLogin")
                                          ) +
                                            "：" +
                                            _vm._s(item.lastLogoutTime || "")
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _c("div", { staticClass: "tips_text" }, [
                            _c("a", {
                              staticClass: "tips_text_a",
                              domProps: {
                                innerHTML: _vm._s(_vm.$sys("warn.noFindRole")),
                              },
                              on: { click: _vm.logout },
                            }),
                          ]),
                        ],
                        2
                      )
                    : [
                        _c("div", { staticClass: "tips_text" }, [
                          _c("a", {
                            staticClass: "tips_text_a",
                            domProps: {
                              innerHTML: _vm._s(_vm.$sys("warn.noFindRole")),
                            },
                            on: { click: _vm.logout },
                          }),
                        ]),
                        _c("van-empty", {
                          attrs: { description: _vm.$sys("account.empty") },
                        }),
                      ],
                ],
                2
              ),
              _c("div", { staticClass: "pop-role-optbar" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handleCancel()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$sys("btn.cancel")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "primary",
                    on: {
                      click: function ($event) {
                        return _vm.onConfirm()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$sys("btn.confirm")) + " ")]
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }