var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page_content" }, [
    _c(
      "div",
      { staticClass: "banner_content" },
      [
        _c(
          "el-carousel",
          {
            staticClass: "banner_carousel",
            attrs: { "indicator-position": "outside" },
          },
          _vm._l(_vm.bannerImag, function (item, index) {
            return _c("el-carousel-item", { key: index }, [
              _c("img", {
                staticClass: "banner_img",
                attrs: { src: item.value, alt: "" },
                on: {
                  click: function ($event) {
                    return _vm.toPage(item.link, index)
                  },
                },
              }),
            ])
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }