<template>
  <div class="pay_layout_banner" v-if="bannerImag?.length > 0">
    <component :bannerImag="bannerImag" :is="isMobile ? 'BannerM' : 'BannerPc'"></component>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BannerPc from './Pc'
import BannerM from './M'

export default {
  name: 'Banner',
  components: {
    BannerPc,
    BannerM
  },
  data() {
    return {
      bannerImag: [],
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters(['isMobile', 'country', 'lang']),
    status() {
      return [this.country, this.lang, this.userInfo]
    }
  },
  methods: {
    ...mapActions('game', ['getPosition'])
  },
  watch: {
    status: {
      async handler() {
        this.bannerImag = await this.getPosition(1)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>