<template>
  <van-popup style="background:transparent" get-container='body' v-model='visibleRole' round :position='isMobile ? "bottom" : "center"'
             @close='onClose()'>
    <van-loading class="roles-loading" v-if="loading" color="#FE7B10" size="60px" />
    <div v-else class='pop-role-container' :class="isMobile?'mobile':'pc'">

      <div class='pop-role-topbar'>
        {{ $sys('account.changeRole') }}
        <van-icon color='#BDBDBD' name='cross' @click='onClose()' />
      </div>

      <div class='pop-role-content'>

        <ul ref='listRef' class='pop-role-list' v-if='roles.length > 0'>
          <li
            :class='["pop-role-list__item", selectedIndex === index ? "selected" : ""]'
            v-for='(item, index) in roles'
            :key='item.gameRoleCode'
            @click='handleSelected(item, index)'>
            <span v-if='item.gameRoleCode === latestLoginRoleCode' class='pop-role-list__mark'>
              {{ $sys('account.latestLogin') }}
            </span>
            <p style='font-size: 15px;'>
              <b>{{ item.gameRoleName }} (LV.{{ item.gameRoleLevel || '' }})</b>
            </p>
            <p style='font-size: 12px;color:#B9B9B9;padding:3px 0;padding-bottom: 0;'>
              <span>{{ $sys('account.server') }}：{{ item.gameZoneName }}</span>&nbsp;&nbsp;
              <span>{{ $sys('account.roleID') }}：{{ item.gameRoleCode }}</span>
              <span
                style="display: block;margin-top:3px;">{{ $sys('account.latestLogin') }}：{{ item.lastLogoutTime || ''
                }}</span>
            </p>
          </li>
          <div class='tips_text'>
            <a class='tips_text_a' @click='logout' v-html="$sys('warn.noFindRole')"></a>
          </div>
        </ul>
        <template v-else>
          <div class='tips_text'>
            <a class='tips_text_a' @click='logout' v-html="$sys('warn.noFindRole')"></a>
          </div>
          <van-empty :description="$sys('account.empty')" />
        </template>
      </div>

      <div class='pop-role-optbar'>
        <button @click='handleCancel()'>
          {{ $sys('btn.cancel') }}
        </button>
        <button class='primary' @click='onConfirm()'>
          {{ $sys('btn.confirm') }}
        </button>
      </div>

    </div>
  </van-popup>

</template>

<script>
import { Popup, Icon, Empty } from 'vant'
import { formatDateTime } from '@/utils'
import { apiBindRole, apiRoleList } from '@/api/user'
import { SELECTED_ROLE_STORE_KEY, removeSelectedRole } from '@/utils/storage'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { isMobile } from '@/utils/utils'
import { Loading } from 'vant'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default() {
        return ''
      }
    },
    visible: {
      type: Boolean,
      require: true
    },
    onlyShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    source: {
      type: Array,
      default() {
        return undefined
      }
    },
    lazyRequest: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [Popup.name]: Popup,
    [Empty.name]: Empty,
    [Icon.name]: Icon,
    [Loading.name]: Loading
  },
  data() {
    return {
      search: '',
      visibleRole: false,
      gameRolesList: [],
      selectedIndex: 0,
      latestLoginRoleCode: null,
      isMobile: isMobile(),
      loading: false
    }
  },
  inject: {
    'handleNoPermission': {
      form: 'handleNoPermission',
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters(['nickname']),
    roles() {
      // return this.gameRolesList.filter(item => item.gameRoleName.toLowerCase().includes(this.search.toLowerCase()))
      return this.gameRolesList
    }
  },
  created() {
    this.visibleRole = this.visible
    this.serverList = []
    this.parseLocalStoreRole()
    if (this.source) {
      this.serverList = this.source
      this.gameRolesList = this.toRoleList(this.serverList)
      if (this.gameRolesList.length) {
        this.init(this.gameRolesList[0])
      }
    } else {
      if (!this.lazyRequest) {
        this.getRoleData()
      }
    }
  },
  methods: {
    ...mapMutations('user', ['SET_USER_INFO']),
    ...mapActions('user', ['logout']),
    async getRoleData() {
      this.loading = true
      const result = await apiRoleList()
      this.handleNoPermission && this.handleNoPermission(false)
      this.loading = false
      if (result) {
        if (result.code === 0) {
          this.serverList = result.data || []
          this.gameRolesList = this.toRoleList(this.serverList)
          this.init(this.gameRolesList[0])
        } else {
          if ((result.code === 12003 || result.code === 13001)) {
            this.handleNoPermission && this.handleNoPermission(true)
          }
        }
      }
    },
    recordRole(roleList, selectedValue) {
      const index = roleList.map(s => s.gameRoleCode).indexOf(selectedValue.gameRoleCode)
      if (index > -1) {
        const tmp = roleList[index]
        this.roles[index] = roleList[0]
        this.roles[0] = tmp
        this.gameRolesList = [...this.roles]
        localStorage.setItem(SELECTED_ROLE_STORE_KEY, JSON.stringify(this.selectedRole))
      }

    },
    async onConfirm(info) {
      if (!this.selectedRole) {
        console.warn('unselected role!')
        return
      }

      const roleInfo = this.selectedRole
      const roleVal = this.selectedRole.gameRoleCode
      const serverInfo = this.serverList.find(item => item.gameZoneCode === this.selectedRole.gameZoneCode)
      if (!this.onlyShow) {

        const result = await apiBindRole(serverInfo?.gameZoneCode, roleVal)

        if (result && result.code === 0) {
          this.SET_USER_INFO({
            ...this.userInfo,
            ...serverInfo,
            ...roleInfo
          })
        }

      }
      this.recordRole(this.roles, roleInfo)
      this.onClose()
      this.$emit('confirm', {
        roleName: roleInfo.gameRoleName,
        roleVal,
        serverName: serverInfo?.gameZoneName,
        serverVal: serverInfo?.gameZoneCode
      })
    },
    onClose() {
      this.visibleRole = false
      this.selectedIndex = 0
      this.selectedRole = this.roles[0]
      this.$emit('update:visible', false)
    },
    handleSelected(item, index) {
      this.selectedRole = item
      this.selectedIndex = index
    },
    handleCancel() {
      this.onClose()
    },
    parseLocalStoreRole() {
      const roleInfo = localStorage.getItem(SELECTED_ROLE_STORE_KEY)
      try {
        this.selectedRole = roleInfo ? JSON.parse(roleInfo) : null
      } catch (e) {
        removeSelectedRole()
        this.selectedRole = null
      }
    },
    toRoleList(list) {
      return list.map(({
                         gameZoneName,
                         gameZoneCode,
                         gameRoleName,
                         gameRoleCode,
                         gameRoleLevel,
                         lastLogoutTime
                       }) => {
        return ({
          gameZoneName,
          gameZoneCode,
          gameRoleLevel,
          lastLogoutTime: formatDateTime(new Date(lastLogoutTime)),
          gameRoleName,
          gameRoleCode
        })
      })
    },
    init(firstRole) {
      const curRole = this.$store.state.user.userInfo || firstRole
      this.latestLoginRoleCode = firstRole ? firstRole.gameRoleCode : null
      this.selectedRole = curRole
      this.recordRole(this.gameRolesList, this.selectedRole)
    },
    updateValue(e) {
      this.$emit('change', e)
    }
  },
  watch: {
    visible(nv, ov) {
      this.visibleRole = nv
      if (nv) {
        if (this.lazyRequest && !this.source) {
          this.getRoleData()
        }
        this.$nextTick(() => {
          if (this.$refs.listRef) {
            this.$refs.listRef.scrollTop = 0
          }
        })
      }
    },
    source(nv, ov) {
      if (nv) {
        this.serverList = nv
        this.gameRolesList = this.toRoleList(nv)
        if (this.gameRolesList.length) {
          this.init(this.gameRolesList[0])
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.pop-role-container {
  max-height: 500px;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color:#ffffff;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(147, 147, 153, 0.5);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }


  &.pc {
    width: 500px;
    height: 330px
  }

  &.mobile {

  }

  .pop-role-topbar {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    background-color: #EFEFEF;
    border-top: 1px solid #D4D4D4;
    line-height: 45px;
    text-align: center;
    font-size: 16px;
    padding: 0 15px;
    font-weight: bold;
    box-shadow: 0 -10px 20px;

    i {
      cursor: pointer;
      float: right;
      margin-top: 10px;
    }
  }

  .pop-role-content {
    max-height: 320px;
    width: 100%;
    padding: 0 16px;
    padding-top: 21px;
    overflow: auto;
    padding-bottom: 20px;

    .pop-role-search {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 10px;

      input {
        border: 1px solid #888888;
        border-radius: 10px;
        height: 38px;
        width: 100%;
      }
    }

    .pop-role-list {
      width: 100%;
      min-height: 200px;

      .pop-role-list__item {
        width: 100%;
        background-color: #F5F5F5;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 12px;
        cursor: pointer;
        margin-bottom: 12px;
        line-height: 21px;
        position: relative;
        cursor: pointer;

        &.selected {
          border: 1px solid #FFA860;
          //outline: 1px solid #FFA860;
        }

        .pop-role-list__mark {
          display: block;
          position: absolute;
          padding: 5px 12px;
          background-color: #FE7B0F;
          border-radius: 6px;
          border-bottom-left-radius: 15px;
          right: 0;
          top: 0;
          color: #fff;
          font-size: 12px;
          border-top-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 14px;
        }
      }
    }

    .tips_text {
      font-size: 14px;
      text-align: center;

      .tips_text_a {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }

  .pop-role-optbar {
    width: 100%;
    height: 60px;
    text-align: center;
    box-shadow: 0 10px 20px;

    button {
      width: 120px;
      height: 36px;
      border: none;
      border-radius: 20px;
      margin: 10px;
      font-size: 14px;
      color: #ffffff;
      background-color: #CDCDCD;
      cursor: pointer;

      &.primary {
        background-color: #FB8525;
      }
    }
  }
}

::v-deep .roles-loading {
  width: 90px;
  height: 90px;
  line-height: 90px;
  background-color:transparent!important;
  text-align: center;

}
</style>