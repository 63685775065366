<template>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerImag" :key="index" @click="toPage(item.link, index)">
            <img class="banner_img" :src="item.value" alt="">
        </van-swipe-item>
    </van-swipe>
</template>

<script>
import { Swipe, SwipeItem } from 'vant'
import { logClickBanner } from '@/utils/log';

export default {
    components: {
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    props: {
        bannerImag: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        toPage(url, index) {
            url && window.open(url, '_blank')
            logClickBanner(index + 1)
        }
    }
}
</script>

<style lang="scss">
.device-m {
    .pay_layout_banner {
        min-width: 0;
        height: 300px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100% auto;

        .my-swipe .van-swipe-item {
            color: #fff;
            font-size: 20px;
            line-height: 300px;
            text-align: center;
        }

        .van-swipe {
            //overflow-y: clip;
            //overflow-y: visible;
            //.van-swipe__indicators{
            //  bottom: -15px;
            //}
        }
    }

    .banner_img {
        width: 750px;
        height: 260px;
        cursor: pointer;
    }
}
</style>