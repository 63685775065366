var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-swipe",
    {
      staticClass: "my-swipe",
      attrs: { autoplay: 3000, "indicator-color": "white" },
    },
    _vm._l(_vm.bannerImag, function (item, index) {
      return _c(
        "van-swipe-item",
        {
          key: index,
          on: {
            click: function ($event) {
              return _vm.toPage(item.link, index)
            },
          },
        },
        [
          _c("img", {
            staticClass: "banner_img",
            attrs: { src: item.value, alt: "" },
          }),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }