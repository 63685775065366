var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.bannerImag?.length > 0
    ? _c(
        "div",
        { staticClass: "pay_layout_banner" },
        [
          _c(_vm.isMobile ? "BannerM" : "BannerPc", {
            tag: "component",
            attrs: { bannerImag: _vm.bannerImag },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }